import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

type ContentContainerProps = {
  children: ReactNode;
};

/**
 * ## Content Container is a responsive container for application layout.
 *
 * Use Content Containers at a *high-level* to provide a consistent
 * user experience. This responsive container provides margins and utilizes
 * a max-width to constrain your content to readable areas when
 * displayed on wider screens.
 *
 * The following are built-in style props being used:
 *
 * - `maxWidth` : `6xl`
 * - `mx` : `auto`
 */
const ContentContainer = ({ children }: ContentContainerProps) => {
  return (
    <Box maxW="6xl" mx="auto" px={{ base: 4, sm: 6, lg: 8 }}>
      {children}
    </Box>
  );
};

export default ContentContainer;
