import React from 'react';
import AppList from '@/src/components/apps/AppList';
import PageHeading from '@/src/shared/components/PageHeading';
import ContentContainer from '@/src/shared/components/ContentContainer';
import { useSession } from 'next-auth/client';
import LogIn from '@/src/components/LogIn';

const Index = () => {
  const [session, loading] = useSession();

  if (typeof window !== 'undefined' && loading) return null;

  if (!session) return <LogIn />;

  return (
    <ContentContainer>
      <PageHeading title="My Apps" />
      <ContentContainer>
        <AppList />
      </ContentContainer>
    </ContentContainer>
  );
};

export default Index;
