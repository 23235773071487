import React from 'react';
import { Grid } from '@chakra-ui/react';

type AppGroupProps = {
  children: React.ReactNode;
};

const AppGroup = ({ children }: AppGroupProps) => {
  return (
    <Grid
      templateColumns={{
        sm: 'repeat(auto-fit, minmax(200px, 1fr))',
        md: 'repeat(auto-fit, minmax(150px, 1fr))',
      }}
      gap={6}
    >
      {children}
    </Grid>
  );
};

export default AppGroup;
