import React from 'react';
import AppGroup from '@/src/components/apps/AppGroup';
import AppCard from '@/src/components/apps/AppCard';
import ManifestIcon from '@/public/static/manifest.png';

const AppList = () => {
  return (
    <AppGroup>
      <AppCard
        route="/manifest"
        title="Manifests"
        icon={ManifestIcon}
        description="Create and manage manifests for deliveries in the Matternet network."
      />
      <AppCard
        route="/tc-manual-data"
        title="TC Input"
        icon={ManifestIcon}
        description="Create and update manual entries for type cert flights."
      />
      <AppCard
        route="/air-traffic-conflict"
        title="AT Conflict"
        icon={ManifestIcon}
        description="Generate simulated airtraffic conflicts."
      />
    </AppGroup>
  );
};

export default AppList;
