import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import ContentContainer from '../ContentContainer';

type PageHeadingProps = {
  /** Title of Page Heading  */
  title: string;
  /** A `<ButtonGroup>` component */
  actions?: React.ReactNode;
};

/**
 * ## Page Heading is a responsive container for providing user context.
 *
 * Use Page Heading to align a title to the rest of your page's content area.
 * The maxWidth settings of Page Heading match thaat of Content Container.
 * No background color is provided by default.
 *
 * - `fontSize`: `2xl`
 * - `mb`: "6"
 *
 */
const PageHeading = ({ title, actions = null }: PageHeadingProps) => {
  return (
    <Box as="header" mb="6">
      <ContentContainer>
        <Flex justify="space-between" alignItems="center">
          <Text fontSize="2xl" fontWeight="bold">
            {title}
          </Text>
          {actions}
        </Flex>
      </ContentContainer>
    </Box>
  );
};

export default PageHeading;
